import React from 'react';
import dayjs from 'dayjs';
import { PlannedPeriodResponse, PlanPeriod as PlanPeriodEnum, StatisticInfoResponse } from 'types';
import { dateFormat, monthFormat, requestDateFormat } from 'utils/constants';

const jsDate = new Date();
export const getNowDayJs = () =>
  dayjs(`${jsDate.getDate()}.${jsDate.getMonth() + 1}.${jsDate.getFullYear()}`, 'D.M.YYYY');
export const now = getNowDayJs();

export const getInitialState = (statistics?: StatisticInfoResponse): DashboardParamsProps => {
  return {
    PlanPeriod: PlanPeriodEnum.M,
    Date: now.format(monthFormat),
    StartDate: now.startOf('month').format(requestDateFormat),
    EndDate: now.endOf('month').format(requestDateFormat),
    StartContractDate: dayjs(statistics?.startDate, dateFormat).format(requestDateFormat),
    EndContractDate: dayjs(statistics?.endDate, dateFormat).format(requestDateFormat),
    CompanyId: undefined,
  };
};

export interface DashboardParamsProps {
  // Y/M/W
  PlanPeriod: PlanPeriodEnum;
  // 2023/09.2023/43.2023
  Date: string;
  // 02.09.2023
  StartDate: string;
  EndDate: string;
  StartContractDate: string;
  EndContractDate: string;
  CompanyId: number | undefined;
}

export const getPlannedPerMonth = (plannedPeriods: PlannedPeriodResponse[], monthNumber: number) => {
  return plannedPeriods
    .filter(x => dayjs(x.date, dateFormat).get('month') + 1 === monthNumber)
    .reduce((prev, item) => prev + item.plannedReportCount, 0);
};

export const dashboardCardBodyStyle: React.CSSProperties = {
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
};

export const getLeftClass = (percentage: number) => {
  switch (percentage) {
    case 1:
      return 'left-[1%]';
    case 2:
      return 'left-[2%]';
    case 3:
      return 'left-[3%]';
    case 4:
      return 'left-[4%]';
    case 5:
      return 'left-[5%]';
    case 6:
      return 'left-[6%]';
    case 7:
      return 'left-[7%]';
    case 8:
      return 'left-[8%]';
    case 9:
      return 'left-[9%]';
    case 10:
      return 'left-[10%]';
    case 11:
      return 'left-[11%]';
    case 12:
      return 'left-[12%]';
    case 13:
      return 'left-[13%]';
    case 14:
      return 'left-[14%]';
    case 15:
      return 'left-[15%]';
    case 16:
      return 'left-[16%]';
    case 17:
      return 'left-[17%]';
    case 18:
      return 'left-[18%]';
    case 19:
      return 'left-[19%]';
    case 20:
      return 'left-[20%]';
    case 21:
      return 'left-[21%]';
    case 22:
      return 'left-[22%]';
    case 23:
      return 'left-[23%]';
    case 24:
      return 'left-[24%]';
    case 25:
      return 'left-[25%]';
    case 26:
      return 'left-[26%]';
    case 27:
      return 'left-[27%]';
    case 28:
      return 'left-[28%]';
    case 29:
      return 'left-[29%]';
    case 30:
      return 'left-[30%]';
    case 31:
      return 'left-[31%]';
    case 32:
      return 'left-[32%]';
    case 33:
      return 'left-[33%]';
    case 34:
      return 'left-[34%]';
    case 35:
      return 'left-[35%]';
    case 36:
      return 'left-[36%]';
    case 37:
      return 'left-[37%]';
    case 38:
      return 'left-[38%]';
    case 39:
      return 'left-[39%]';
    case 40:
      return 'left-[40%]';
    case 41:
      return 'left-[41%]';
    case 42:
      return 'left-[42%]';
    case 43:
      return 'left-[43%]';
    case 44:
      return 'left-[44%]';
    case 45:
      return 'left-[45%]';
    case 46:
      return 'left-[46%]';
    case 47:
      return 'left-[47%]';
    case 48:
      return 'left-[48%]';
    case 49:
      return 'left-[49%]';
    case 50:
      return 'left-[50%]';
    case 51:
      return 'left-[51%]';
    case 52:
      return 'left-[52%]';
    case 53:
      return 'left-[53%]';
    case 54:
      return 'left-[54%]';
    case 55:
      return 'left-[55%]';
    case 56:
      return 'left-[56%]';
    case 57:
      return 'left-[57%]';
    case 58:
      return 'left-[58%]';
    case 59:
      return 'left-[59%]';
    case 60:
      return 'left-[60%]';
    case 61:
      return 'left-[61%]';
    case 62:
      return 'left-[62%]';
    case 63:
      return 'left-[63%]';
    case 64:
      return 'left-[64%]';
    case 65:
      return 'left-[65%]';
    case 66:
      return 'left-[66%]';
    case 67:
      return 'left-[67%]';
    case 68:
      return 'left-[68%]';
    case 69:
      return 'left-[69%]';
    case 70:
      return 'left-[70%]';
    case 71:
      return 'left-[71%]';
    case 72:
      return 'left-[72%]';
    case 73:
      return 'left-[73%]';
    case 74:
      return 'left-[74%]';
    case 75:
      return 'left-[75%]';
    case 76:
      return 'left-[76%]';
    case 77:
      return 'left-[77%]';
    case 78:
      return 'left-[78%]';
    case 79:
      return 'left-[79%]';
    case 80:
      return 'left-[80%]';
    case 81:
      return 'left-[81%]';
    case 82:
      return 'left-[82%]';
    case 83:
      return 'left-[83%]';
    case 84:
      return 'left-[84%]';
    case 85:
      return 'left-[85%]';
    case 86:
      return 'left-[86%]';
    case 87:
      return 'left-[87%]';
    case 88:
      return 'left-[88%]';
    case 89:
      return 'left-[89%]';
    case 90:
      return 'left-[90%]';
    case 91:
      return 'left-[91%]';
    case 92:
      return 'left-[92%]';
    case 93:
      return 'left-[93%]';
    case 94:
      return 'left-[94%]';
    case 95:
      return 'left-[95%]';
    case 96:
      return 'left-[96%]';
    case 97:
      return 'left-[97%]';
    case 98:
      return 'left-[98%]';
    case 99:
      return 'left-[99%]';
    case 100:
      return 'left-[100%]';

    default:
      return 'left-[0%]';
  }
};
