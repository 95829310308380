import dayjs from 'dayjs';
import { getNowDayJs } from 'pages/DashboardPage/utils';
import { PlanPeriod } from 'types';
import { requestDateFormat } from 'utils/constants';

const pageSize = 10000;
const today = getNowDayJs();

export const initialParams = {
  pageSize,
  isAwaitingCompanyAction: false,
  isAwaitingUserAction: false,
  onlyCreatedFromUnscheduledTask: false,
  period: PlanPeriod.M,
  date: today,
  from: dayjs(today).startOf('month').format(requestDateFormat),
  to: dayjs(today).endOf('month').format(requestDateFormat),
};
